.container {
    width: 100%;
    color: #1d1d1d;
}

.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 106px);
    padding-bottom: 100px;
}

.content-box>h3 {
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
}

.content-box>label {
    font-size: 2rem;
    font-weight: 400;
    width: 40%;
    text-align: center;
}