.container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: #000000;
    padding: 2rem;
}

.text-container > h3 {
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
}

.text-container > label {
    font-size: 2rem;
    font-weight: 400;
}