.container {
  display: flex;
  align-items: center;
  margin: 30px 0 0 30px;
}

.center {
  flex-direction: column;
  justify-content: center;
} 

.logo-initial {
  background-color: var(--primary-green);
  width: 2.7rem;
  height: 2.7rem;
  color: white;
  font-size: 20px;
  font-weight: 700;
  border-radius: 6px;
  padding-left: 7px;
}